.textStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.whiteLink {
    color: white;
    text-decoration: none; 
}

.whiteLink:hover {
    text-decoration: underline; 
}

.Form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 200px; 
}

